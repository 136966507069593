.modal {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, .6);
    z-index: 9999;
}

.modal-dialog {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 90%;
    padding: 10px 0;
    border-radius: 3px;
    background: #ffffff;
}

.stageSwitcherList {
    list-style: none;
    padding: 0;
}

.stageSwitcherList li.stage {
    padding: 20px 15px;
    font-family: sans-serif;
    font-size: 18px;
    color: #000000;
}

.stageSwitcherList li.stage .checkbox {
    position: relative;
    border: 2px solid #000000;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    float: right;
}

.stageSwitcherList li.stage .checkbox.checked:before {
    content: '';
    position: absolute;
    background: #000000;
    border-radius: 50%;
    width: 12px;
    height: 12px;
    left: 2px;
    top: 2px;
}

.stageSwitcherList li.stage:not(:last-child) {
    border-bottom: 1px solid rgba(0, 0, 0, .1);
}

.empty-flag {
    display: inline-block;
}

.Loader {
    position: fixed;
    width: 100%;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    text-align: center;
    padding: 30px;
}

.Loader img {
    max-width: 100%;
    display: inline-block;
}

.Loader span {
    margin-top: 30px;
    display: block;
    font-family: sans-serif;
    font-size: 20px;
    font-weight: bold;
}
@font-face {
  font-family: Dosis;
  font-weight: 200;
  src: url(../../static/media/Dosis-ExtraLight.48dcd270.woff) format("woff"), url(../../static/media/Dosis-ExtraLight.b7c31e7b.ttf) format("truetype"), url(../../static/media/Dosis-ExtraLight.aeca87cc.otf) format("opentype");
}
@font-face {
  font-family: Dosis;
  font-weight: 300;
  src: url(../../static/media/Dosis-Light.4359cfdf.woff) format("woff"), url(../../static/media/Dosis-Light.d7153198.ttf) format("truetype"), url(../../static/media/Dosis-Light.3b377d61.otf) format("opentype");
}
@font-face {
  font-family: Dosis;
  src: url(../../static/media/Dosis-Regular.a2006c07.woff) format("woff"), url(../../static/media/Dosis-Regular.3000d110.ttf) format("truetype"), url(../../static/media/Dosis-Regular.62edbe8d.otf) format("opentype");
}
@font-face {
  font-family: Dosis;
  font-weight: 500;
  src: url(../../static/media/Dosis-Medium.7710a509.woff) format("woff"), url(../../static/media/Dosis-Medium.c488265a.ttf) format("truetype"), url(../../static/media/Dosis-Medium.9878be19.otf) format("opentype");
}
@font-face {
  font-family: Dosis;
  font-weight: 600;
  src: url(../../static/media/Dosis-SemiBold.d4153652.woff) format("woff"), url(../../static/media/Dosis-SemiBold.1ded42fe.ttf) format("truetype"), url(../../static/media/Dosis-SemiBold.59e4de58.otf) format("opentype");
}
@font-face {
  font-family: Dosis;
  font-weight: 700;
  src: url(../../static/media/Dosis-Bold.419a7f99.woff) format("woff"), url(../../static/media/Dosis-Bold.8c111116.ttf) format("truetype"), url(../../static/media/Dosis-Bold.3fdafce4.otf) format("opentype");
}
@font-face {
  font-family: Dosis;
  font-weight: 800;
  src: url(../../static/media/Dosis-ExtraBold.f16ae80e.woff) format("woff"), url(../../static/media/Dosis-ExtraBold.71a38ba3.ttf) format("truetype"), url(../../static/media/Dosis-ExtraBold.dcd2da8a.otf) format("opentype");
}
.btn {
  background-color: transparent;
  border: none;
}
.btn img {
  width: 36px;
}
.btn img:focus {
  outline: none;
}
.btn:focus {
  outline: none;
}
.btn-group--vertical {
  position: absolute;
  top: 20px;
  right: 30px;
  width: 36px;
  margin-top: 7px;
}
.btn-group--vertical .btn {
  margin-bottom: 10px;
}
.btn-group-spacer {
  width: 36px;
  height: 36px;
  display: inline-block;
  margin-bottom: 10px;
}
/* PAGES */
.page--menu {
  padding: 40px 30px 0;
}
.page--menu #cdm_logo {
  max-width: 67.3%;
  margin: 0 auto 100px;
  display: block;
}
.page--menu .menu {
  list-style: none;
  padding: 0;
}
.page--menu .menu .menu__item {
  margin-bottom: 20px;
}
.page--menu .menu .menu__item a,
.page--menu .menu .menu__item button {
  background: rgba(255, 255, 255, 0.8);
  border: none;
  display: block;
  width: 100%;
  border-radius: 30px;
  padding: 14px;
  font-size: 25px;
  font-weight: 800;
  color: #000000;
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;
}
.page--calendar {
  padding: 20px 30px 54px;
}
.page--calendar header {
  margin-bottom: 15px;
}
.page--calendar header:after {
  content: '';
  display: table;
  clear: both;
}
.page--calendar header .btn--back {
  vertical-align: bottom;
  margin-bottom: 10px;
}
.page--calendar header #quartal {
  float: right;
  margin-top: 25px;
  margin-right: -15px;
  font-size: 20px;
  font-weight: 700;
  color: #d8005d;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}
.page--calendar header h1 {
  position: relative;
  padding: 6px 0;
  width: calc(100% - 36px);
  float: left;
  font-size: 22px;
  text-transform: uppercase;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
  color: #ffffff;
}
.page--calendar header h1:before {
  content: '';
  position: absolute;
  top: 0;
  left: -30px;
  width: calc(100% + 81px);
  height: 1px;
  background: #d8005d;
}
.page--calendar header h1:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: -30px;
  width: calc(100% + 30px);
  height: 1px;
  background: #d8005d;
}
.page--calendar header .btn--livecontent {
  float: right;
}
.page--calendar .calendar__list {
  list-style: none;
  padding: 0;
}
.page--calendar .calendar__list .calendar__item {
  margin-bottom: 20px;
  background: rgba(255, 255, 255, 0.8);
  display: block;
  width: 100%;
  border-radius: 30px;
  padding: 14px;
}
.page--calendar .calendar__list .calendar__item .btn--timetable {
  width: 10%;
}
.page--calendar .calendar__list .calendar__item .btn--timetable img {
  max-width: 22px;
}
.page--calendar .calendar__list .calendar__item .btn--calendar-event {
  width: 80%;
  font-size: 16px;
  font-weight: 700;
  color: #000000;
  text-align: center;
}
.page--calendar .calendar__list .calendar__item .btn--results {
  width: 10%;
}
.page--calendar .calendar__list .calendar__item .btn--results img {
  max-width: 22px;
}
.page--calendar .calendar__list .calendar__item .btn-group-spacer {
  display: inline-block;
  width: 10%;
  height: 22px;
  margin-bottom: 0;
}
.page--calendar-detail {
  padding: 20px 30px 75px;
}
.page--calendar-detail header {
  margin-bottom: 15px;
}
.page--calendar-detail header:after {
  content: '';
  display: table;
  clear: both;
}
.page--calendar-detail header .btn--back {
  vertical-align: bottom;
  margin-bottom: 10px;
}
.page--calendar-detail header #date {
  float: right;
  margin-top: 25px;
  margin-right: -15px;
  font-size: 20px;
  font-weight: 700;
  color: #d8005d;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}
.page--calendar-detail header h1 {
  position: relative;
  padding: 10px 0;
  width: calc(100% - 45px);
  float: left;
  font-size: 22px;
  text-transform: uppercase;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
  color: #ffffff;
}
.page--calendar-detail header h1:before {
  content: '';
  position: absolute;
  top: 0;
  left: -30px;
  width: calc(100% + 81px);
  height: 1px;
  background: #d8005d;
}
.page--calendar-detail header h1:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: -30px;
  width: calc(100% + 30px);
  height: 1px;
  background: #d8005d;
}
.page--calendar-detail header h2 {
  float: left;
  margin-top: 10px;
  font-size: 14px;
  text-transform: uppercase;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
  color: #ffffff;
}
.page--calendar-detail header .btn-group--vertical {
  position: relative;
  right: 0;
  top: 0;
  float: right;
  width: 36px;
  margin-top: 7px;
}
.page--calendar-detail .container--event-info {
  width: 100%;
  padding: 20px 15px;
  border-radius: 30px;
  background: rgba(255, 255, 255, 0.8);
}
.page--livecontent {
  padding: 20px 30px 0;
}
.page--livecontent .btn--back {
  position: absolute;
}
.page--livecontent #cdm_logo {
  max-width: 57.8%;
  margin: 0 auto 30px;
  display: block;
}
.page--livecontent h1 {
  position: relative;
  padding: 10px 0;
  width: calc(100% - 9px);
  float: left;
  font-size: 20px;
  text-transform: uppercase;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
  color: #ffffff;
}
.page--livecontent h1:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: -30px;
  width: calc(100% + 45px);
  height: 1px;
  background: #d8005d;
}
.page--livecontent h1 #date {
  float: right;
  margin-right: -15px;
  color: #d8005d;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}
.page--livecontent h2 {
  float: left;
  margin-top: 10px;
  font-size: 14px;
  text-transform: uppercase;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
  color: #ffffff;
}
.page--livecontent .container--livecontent {
  clear: both;
  padding-top: 20px;
}
.page--livecontent .container--livecontent .variable {
  width: 100%;
  margin-bottom: 20px;
  background: rgba(255, 255, 255, 0.8);
  display: block;
  border-radius: 30px;
  padding: 14px;
  font-size: 23px;
  font-weight: 800;
  color: #000000;
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;
}
.page--livecontent .container--livecontent .variable[id="startovka.Text"] {
  width: auto;
  display: inline-block;
  font-size: 48px;
  font-weight: 800;
  padding: 0 50px;
}
.page--livecontent .container--livecontent .variable[id="heat.Text"] {
  width: auto;
  display: inline-block;
  font-size: 18px;
  font-weight: 600;
  padding: 14px 30px;
}
.page--livecontent .heat__round__heat-table {
  width: 100%;
  margin: 15px auto 0;
  border-spacing: 0;
  border: 2px solid #ffffff;
}
.page--livecontent .heat__round__heat-table thead th {
  padding: 7px 10px 3px;
  background: #000080;
  font-family: "Dosis", sans-serif;
  font-weight: 600;
  text-transform: uppercase;
  text-align: center;
  color: #ffffff;
}
.page--livecontent .heat__round__heat-table tbody tr td {
  padding: 7px 10px 3px;
  border-top: 3px solid #ffffff;
  border-bottom: 3px solid #ffffff;
  font-family: "Dosis", sans-serif;
  font-weight: 600;
  text-align: center;
  background: #ffffff;
}
.page--livecontent .heat__round__heat-table tbody tr:nth-child(even) td {
  background: #e2e2e2;
}
.page--results,
.page--results-detail,
.page--realtime-results {
  padding: 20px 30px 50px;
}
.page--results header,
.page--results-detail header,
.page--realtime-results header {
  margin-bottom: 15px;
}
.page--results header:after,
.page--results-detail header:after,
.page--realtime-results header:after {
  content: '';
  display: table;
  clear: both;
}
.page--results header .btn--back,
.page--results-detail header .btn--back,
.page--realtime-results header .btn--back {
  vertical-align: bottom;
  margin-bottom: 10px;
}
.page--results header #date,
.page--results-detail header #date,
.page--realtime-results header #date {
  float: right;
  margin-top: 25px;
  margin-right: -15px;
  font-size: 20px;
  font-weight: 700;
  color: #d8005d;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}
.page--results header h1,
.page--results-detail header h1,
.page--realtime-results header h1 {
  position: relative;
  padding: 10px 0;
  width: calc(100% - 45px);
  float: left;
  font-size: 22px;
  text-transform: uppercase;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
  color: #ffffff;
}
.page--results header h1:before,
.page--results-detail header h1:before,
.page--realtime-results header h1:before {
  content: '';
  position: absolute;
  top: 0;
  left: -30px;
  width: calc(100% + 81px);
  height: 1px;
  background: #d8005d;
}
.page--results header h1:after,
.page--results-detail header h1:after,
.page--realtime-results header h1:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: -30px;
  width: calc(100% + 30px);
  height: 1px;
  background: #d8005d;
}
.page--results header .btn--results,
.page--results-detail header .btn--results,
.page--realtime-results header .btn--results {
  float: right;
  margin-top: 5px;
}
.page--results header h2,
.page--results-detail header h2,
.page--realtime-results header h2 {
  float: left;
  margin-top: 10px;
  font-size: 14px;
  text-transform: uppercase;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
  color: #ffffff;
}
.page--results .results__list {
  padding-left: 0;
  list-style: none;
}
.page--results .results__list .results__list__item {
  margin-bottom: 15px;
}
.page--results .results__list .results__list__item a {
  background: rgba(255, 255, 255, 0.7);
  border: none;
  display: block;
  width: 100%;
  border-radius: 30px;
  padding: 14px;
  font-size: 14px;
  font-weight: 800;
  color: #000000;
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;
}
.page--results-detail .results-container .round-title,
.page--realtime-results .results-container .round-title {
  margin-bottom: 35px;
  background: rgba(255, 255, 255, 0.8);
  display: block;
  width: 100%;
  border-radius: 30px;
  padding: 14px;
  font-size: 15pt;
  font-weight: 700;
  color: #000000;
  text-align: center;
  text-transform: uppercase;
}
.page--results-detail .results-container .results-table,
.page--realtime-results .results-container .results-table {
  width: 95%;
  margin: 0 auto;
  border-spacing: 0;
  border: 1px solid #ffffff;
  background: rgba(255, 255, 255, 0.8);
}
.page--results-detail .results-container .results-table thead th,
.page--realtime-results .results-container .results-table thead th {
  background: rgba(0, 0, 128, 0.8);
  padding: 3px 5px 3px;
  border-right: 1px solid #ffffff;
  border-bottom: 2px solid #ffffff;
  font-weight: bold;
  font-size: 12pt;
  text-align: center;
  color: #ffffff;
}
.page--results-detail .results-container .results-table thead th:first-child,
.page--realtime-results .results-container .results-table thead th:first-child {
  border-left: 0;
}
.page--results-detail .results-container .results-table thead th:last-child,
.page--realtime-results .results-container .results-table thead th:last-child {
  border-right: 0;
}
.page--results-detail .results-container .results-table thead th.results-table__column--pos,
.page--realtime-results .results-container .results-table thead th.results-table__column--pos {
  width: 40px;
}
.page--results-detail .results-container .results-table thead th.results-table__column--stn,
.page--realtime-results .results-container .results-table thead th.results-table__column--stn {
  width: 40px;
}
.page--results-detail .results-container .results-table thead th.results-table__column--qualified,
.page--realtime-results .results-container .results-table thead th.results-table__column--qualified {
  width: 40px;
}
.page--results-detail .results-container .results-table thead th.results-table__column--medal,
.page--realtime-results .results-container .results-table thead th.results-table__column--medal {
  width: 40px;
  text-align: center;
}
.page--results-detail .results-container .results-table thead th.results-table__column--medal img,
.page--realtime-results .results-container .results-table thead th.results-table__column--medal img {
  width: 24px;
  vertical-align: middle;
}
.page--results-detail .results-container .results-table tbody tr td,
.page--realtime-results .results-container .results-table tbody tr td {
  padding: 3px 5px 3px;
  border-right: 1px solid #ffffff;
  border-bottom: 1px solid #ffffff;
  font-weight: bold;
  font-size: 12pt;
  color: #000000;
}
.page--results-detail .results-container .results-table tbody tr td.results-table__column--pos,
.page--realtime-results .results-container .results-table tbody tr td.results-table__column--pos {
  text-align: center;
}
.page--results-detail .results-container .results-table tbody tr td.results-table__column--stn,
.page--realtime-results .results-container .results-table tbody tr td.results-table__column--stn {
  text-align: center;
}
.page--results-detail .results-container .results-table tbody tr td.results-table__column--medal,
.page--realtime-results .results-container .results-table tbody tr td.results-table__column--medal {
  text-align: center;
}
.page--results-detail .results-container .results-table tbody tr td.results-table__column--flag,
.page--realtime-results .results-container .results-table tbody tr td.results-table__column--flag {
  width: 40px;
  text-align: center;
}
.page--results-detail .results-container .results-table tbody tr td.results-table__column--flag img,
.page--realtime-results .results-container .results-table tbody tr td.results-table__column--flag img {
  width: 24px;
  vertical-align: middle;
}
.page--results-detail .results-container .results-table tbody tr td.results-table__column--std,
.page--realtime-results .results-container .results-table tbody tr td.results-table__column--std {
  width: 95px;
}
.page--results-detail .results-container .results-table tbody tr td.results-table__column--std img,
.page--realtime-results .results-container .results-table tbody tr td.results-table__column--std img {
  width: 24px;
  margin-right: 10px;
  vertical-align: middle;
}
.page--results-detail .results-container .results-table tbody tr td.results-table__column--std .empty-flag,
.page--realtime-results .results-container .results-table tbody tr td.results-table__column--std .empty-flag {
  width: 24px;
  height: 18px;
  margin-right: 10px;
  vertical-align: middle;
}
.page--results-detail .results-container .results-table tbody tr td.results-table__column--qualified,
.page--realtime-results .results-container .results-table tbody tr td.results-table__column--qualified {
  text-align: center;
}
.page--results-detail .results-container .results-table tbody tr td.results-table__column--qualified img,
.page--realtime-results .results-container .results-table tbody tr td.results-table__column--qualified img {
  width: 24px;
  vertical-align: middle;
}
.page--results-detail .results-container .results-table tbody tr:nth-child(even) td,
.page--realtime-results .results-container .results-table tbody tr:nth-child(even) td {
  background: rgba(0, 0, 128, 0.2);
}
.page--results-detail .results-container .results-table tbody tr:nth-child(odd) td,
.page--realtime-results .results-container .results-table tbody tr:nth-child(odd) td {
  background: rgba(255, 255, 255, 0.1);
}
.page--results-detail .results-container .results-table tbody tr:last-child td,
.page--realtime-results .results-container .results-table tbody tr:last-child td {
  border-bottom: 0;
}
.page--timetable {
  padding: 20px 30px 50px;
}
.page--timetable header {
  margin-bottom: 15px;
}
.page--timetable header:after {
  content: '';
  display: table;
  clear: both;
}
.page--timetable header .btn--back {
  vertical-align: bottom;
  margin-bottom: 10px;
}
.page--timetable header #date {
  float: right;
  margin-top: 25px;
  margin-right: 45px;
  font-size: 20px;
  font-weight: 700;
  color: #d8005d;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}
.page--timetable header h1 {
  position: relative;
  padding: 10px 0;
  width: calc(100% - 45px);
  float: left;
  font-size: 22px;
  text-transform: uppercase;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
  color: #ffffff;
}
.page--timetable header h1:before {
  content: '';
  position: absolute;
  top: 0;
  left: -30px;
  width: calc(100% + 30px);
  height: 1px;
  background: #d8005d;
}
.page--timetable header h1:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: -30px;
  width: calc(100% + 30px);
  height: 1px;
  background: #d8005d;
}
.page--timetable header h2 {
  float: left;
  margin-top: 10px;
  font-size: 14px;
  text-transform: uppercase;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
  color: #ffffff;
}
.page--timetable .heat__date-row {
  border: 2px solid #ffffff;
  background: rgba(255, 255, 255, 0.8);
}
.page--timetable .heat__date-row .heat__date-head {
  background: rgba(0, 0, 128, 0.8);
  padding: 7px 10px;
  font-family: "Bahnschrift", sans-serif;
  font-weight: bold;
  font-size: 14pt;
  color: #ffffff;
}
.page--timetable .heat__date-row .heat__date__rounds-head {
  background: rgba(0, 0, 128, 0.6);
  padding: 7px 5px;
  font-family: "Bahnschrift", sans-serif;
  font-weight: bold;
  font-size: 11pt;
  color: #ffffff;
}
.page--timetable .heat__date-row .heat__round_time {
  display: inline-block;
  width: 120px;
  text-align: center;
}
.page--timetable .heat__date-row .heat__round_title {
  display: inline-block;
  width: calc(100% - 145px);
  vertical-align: top;
}
.page--timetable .heat__date-row:not(:last-child) {
  border-bottom: none;
}
.page--timetable .heat__round-row {
  background: rgba(0, 0, 128, 0.2);
  padding: 5px 5px 0;
  font-weight: bold;
  font-size: 11pt;
  color: #000000;
}
.page--timetable .heat__round-row .heat__round-head {
  position: relative;
  padding-bottom: 5px;
}
.page--timetable .heat__round-row .heat__round-head ._caret {
  position: absolute;
  top: 50%;
  right: -3px;
  width: 25px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  padding: 5px;
}
.page--timetable .heat__round-row:nth-child(odd) {
  background: rgba(255, 255, 255, 0.1);
}
.page--timetable .heat__round-content {
  margin: 0 -5px;
  background: rgba(255, 255, 255, 0.5);
  border-bottom: 2px solid #ffffff;
}
.page--timetable .heat__round-content .heat__round__heat-table {
  width: 100%;
  border-spacing: 0;
}
.page--timetable .heat__round-content .heat__round__heat-table thead th {
  background: rgba(42, 45, 149, 0.5);
  padding: 7px 5px;
  font-weight: bold;
  font-size: 11pt;
  color: #ffffff;
}
.page--timetable .heat__round-content .heat__round__heat-table td {
  padding: 2px 0;
}
.page--timetable .heat__round-content .heat__round__heat-table .heat_heat-row--white td,
.page--timetable .heat__round-content .heat__round__heat-table .heat_heat-competitor-row--white td {
  background: rgba(255, 255, 255, 0.5);
}
.page--timetable .heat__round-content .heat__round__heat-table .heat_heat-row--gray td,
.page--timetable .heat__round-content .heat__round__heat-table .heat_heat-competitor-row--gray td {
  background: rgba(42, 45, 149, 0.5);
  color: #ffffff;
}
.page--timetable .heat__round-content .heat__round__heat-table .heat__heat-id {
  min-width: 35px;
  text-align: center;
}
.page--timetable .heat__round-content .heat__round__heat-table .heat__heat-stn,
.page--timetable .heat__round-content .heat__round__heat-table .heat_heat-competitor-stn {
  text-align: center;
}
html,
body {
  width: 100%;
  height: 100%;
}
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: Dosis, sans-serif;
}
body {
  background: url(../../static/media/background.eafbc80d.jpg) fixed no-repeat center center;
  background-color: #1c1d22;
  background-size: cover;
}
.text-center {
  text-align: center;
}
.eventSwitcherList {
  list-style: none;
  padding: 0;
}
.eventSwitcherList li.event {
  padding: 20px 15px;
  font-family: sans-serif;
  font-size: 18px;
  color: #000000;
}
.eventSwitcherList li.event:not(:last-child) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.crp-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  background: #ffffff;
  text-align: center;
  padding: 7px 0;
}
/*# sourceMappingURL=CDM-App.css.map */
